import React from "react"

const formImageSrcSet = params => {
  const keys = Object.keys(params.transforms),
    keysLength = keys.length;
  let imageSrcSet = {
    src: params.images[keys[keysLength - 1]],
    srcSet: '',
    sizes: ''
  };
  for (let index = 0; index < keysLength; index++) {
    let width = keys[index].substr(0, keys[index].search(/[a-z]/i));
    imageSrcSet.srcSet += `${params.images[keys[index]]} ${width}w, `
    imageSrcSet.sizes += `${params.transforms[keys[index]]} ${width}px, `
  }
  return imageSrcSet;
}

const SrcSetImage = ({props}) => {
  if(props.images){
    const ImageSrcSet = formImageSrcSet(props);
    return <img alt={"image place"} {...ImageSrcSet}
                {...props.attr}
                loading="lazy"/>
  }
  return "";
}

export default SrcSetImage;
